body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: 62.5%;
  width: 100%;
}

#map {
  width: 100%;
  height: 100%;
}